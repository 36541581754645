import { groq } from 'next-sanity';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import Button, { buttonVariants } from '../../../components/Button';
import { Image } from '../../../components/Image';
import Layout from '../../../components/Layout';
import OpeningHours from '../../../components/OpeningHours';
import PortableText from '../../../components/PortableText';
import Section from '../../../components/Section';
import ArticleCard, { ArticleCardHorizontal } from '../../../components/cards/ArticleCard';
import ArticleCardBig from '../../../components/cards/ArticleCardBig';
import LinkCard from '../../../components/cards/LinkCard';
import { dealerPageQuery } from '../../../groq/dealer';
import { sanityClient } from '../../../lib/sanity.server';
import {
  CampaignSingularType,
  DealerType,
  DefaultInfoDealerType,
  DefaultSEOType,
  ExternalLinkType,
  MenuType,
  ServiceType,
  SettingsType,
  TitleDescriptionLinksType,
} from '../../../types';
import cn from '../../../utils/cn';
import fetchSanityData from '../../../utils/fetchSanityData';

import HeroCTA, { HeroCTARef } from '../../../components/HeroCTA';
import SpecialOpeningHours from '../../../components/SpecialOpeningHours';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../components/ui/accordion';
import CampaignCard from '../../../components/cards/CampaignCard';
import Announcement from '../../../components/Announcement';

interface PageProps {
  data: {
    content: DealerType & {
      defaultSEO: DefaultSEOType;
      announcements?: TitleDescriptionLinksType[];
      serviceCarBrands: { title: string; _id: string }[];
      dealersOverride?: {
        audi?: string;
        skoda?: string;
        volkswagen?: string;
        volkswagenNyttekjoretoy?: string;
      };
      defaultInfoDealer: DefaultInfoDealerType;
    };
    menus: MenuType;
    settings: SettingsType;
  };
  slug: string;
}

function DealerPage({ data, slug }: PageProps) {
  const { content, menus, settings } = data;

  const {
    _id,
    dealerId,
    email,
    phone,
    title,
    overrideRelatedServices,
    usedCarsLink,
    isRepairCentre,
    defaultInfoDealer,
    formOverride,
    description,
    overline,
    serviceCarBrands,
    image,
    partners,
    localCustomerBenefits,
    openingHoursSale,
    openingHoursWarehouse,
    openingHoursGarage,
    easterOpeningHours,
    christmasOpeningHours,
    summerOpeningHours,
    relatedArticlesFeatureDealer,
    relatedServices,
    relatedCarBrands,
    addressInfoList,
    seo,
    defaultSEO,
    dealersOverride,
    announcements,
  } = content;

  const startDateEaster = new Date(easterOpeningHours?.startDate) || undefined;
  const endDateEaster = new Date(easterOpeningHours?.endDate) || undefined;
  const startDateChristmas = new Date(christmasOpeningHours?.startDate) || undefined;
  const endDateChristmas = new Date(christmasOpeningHours?.endDate) || undefined;
  const startDateSummer = new Date(summerOpeningHours?.startDate) || undefined;
  const endDateSummer = new Date(summerOpeningHours?.endDate) || undefined;

  const today = new Date(Date.now());

  const isEaster =
    startDateEaster && endDateEaster && startDateEaster <= today && endDateEaster >= today;
  const isChristmas =
    startDateChristmas &&
    endDateChristmas &&
    startDateChristmas <= today &&
    endDateChristmas >= today;
  const isSummer =
    startDateSummer && endDateSummer && startDateSummer <= today && endDateSummer >= today;

  const hasSpecialOpeningHours = isEaster || isChristmas || isSummer;

  const [campaigns, setCampaigns] = useState<CampaignSingularType[]>([]);
  const [transverseCampaign, setTransverseCampaign] = useState<CampaignSingularType | undefined>();

  useEffect(() => {
    const fetchCampaigns: () => void = async () => {
      const response = await fetchSanityData('dealerCampaigns', { id: _id });
      const transverseCampaignRespons = response.transverseCampaign?.[0];
      const showOnDealersList =
        transverseCampaignRespons?.showOnDealerObject?.dealers?.map(
          (d: { _ref: string }) => d._ref,
        ) || [];
      if (
        transverseCampaignRespons?.showOnDealerObject?.showOnDealer &&
        showOnDealersList.includes(_id)
      ) {
        setTransverseCampaign(transverseCampaignRespons);
      }
      setCampaigns(response.campaigns);
    };

    fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Remove this object when employees/Azure-integration is fixed
  const getTempUrl = (brandSlug: string) => {
    if (!dealersOverride) return undefined;

    switch (brandSlug) {
      case 'audi': {
        return dealersOverride.audi;
      }
      case 'volkswagen': {
        return dealersOverride.volkswagen;
      }
      case 'volkswagen-nyttekjoretoy': {
        return dealersOverride.volkswagenNyttekjoretoy;
      }
      case 'skoda': {
        return dealersOverride.skoda;
      }
      default:
        return undefined;
    }
  };

  const carBrandsList = [
    ...relatedCarBrands.map((brand) => {
      return {
        _key: brand._id,
        linkText: brand.title,
        contentType: '',
        linkType: '',
        href: getTempUrl(brand.slug) || `/kjope-bil/nybil/${brand.slug}`,
      };
    }),
  ];

  const titleSort = (a: { title: string }, b: { title: string }) =>
    a.title.localeCompare(b.title, 'nb-NO');

  const relatedServicesList = relatedServices
    ?.map((service: ServiceType) => {
      const loginUrl = service.loginOverride?.login?.cta?.url;
      return {
        href: service.serviceId
          ? `${loginUrl}?dealer=${dealerId}&serviceIds=${service.serviceId}`
          : `${loginUrl}?dealer=${dealerId}`,
        title: service.title,
        _id: service._id,
      };
    })
    .sort(titleSort);

  const overrideRelatedServicesList = overrideRelatedServices
    ?.map((link: ExternalLinkType) => ({
      href: link.url,
      title: link.linkText,
      _id: link._key,
    }))
    .sort(titleSort);

  const filteredServiceCarBrands = serviceCarBrands.filter((serviceBrand) => {
    const brandNames = carBrandsList.map((item) => item.linkText.toLowerCase().trim());

    if (brandNames.includes(serviceBrand.title.toLowerCase().trim())) return false;
    return true;
  });

  const heroCTARef = useRef<HeroCTARef>(null);

  const openModal = () => {
    if (heroCTARef.current) {
      heroCTARef.current.openModal();
    }
  };

  return (
    <Layout settings={settings} seo={seo} menus={menus} defaultSEO={defaultSEO}>
      <HeroCTA
        overline={overline}
        title={title}
        formOverride={formOverride}
        ref={heroCTARef}
        dealerId={dealerId}
        findDealer={false}
      />
      {(description || image?.asset) && (
        <Section>
          <div className="grid items-center sm:grid-cols-2 sm:gap-10">
            {description && (
              <p className="whitespace-pre-line py-10 pr-6 sm:p-0 sm:text-lg lg:pr-20">
                {description}
              </p>
            )}
            {image?.asset && (
              <Image src={image} width={(400 / 9) * 16} height={400} className="rounded-md" />
            )}
          </div>
        </Section>
      )}{' '}
      {Array.isArray(announcements) && announcements.length > 0 && (
        <>
          {announcements.map((announcement) => {
            return (
              <Section key={announcement.title}>
                <Announcement value={announcement} />
              </Section>
            );
          })}
        </>
      )}
      {transverseCampaign && (
        <Section>
          <CampaignCard
            key={transverseCampaign._id}
            overline={transverseCampaign.overline}
            title={transverseCampaign.title}
            description={transverseCampaign.pitch}
            href={transverseCampaign.href || '/kampanjer'}
            image={transverseCampaign.image}
          />
        </Section>
      )}
      {campaigns.length > 0 && (
        <Section>
          {campaigns.map((campaign) => {
            return (
              <CampaignCard
                key={campaign._id}
                title={campaign.title}
                description={campaign.pitch}
                image={campaign.image}
                href={`/forhandler/${slug}/${encodeURI(campaign._id)}`}
                overline={campaign.overline}
              />
            );
          })}
        </Section>
      )}
      {/* SERVICES */}
      {!!overrideRelatedServices?.length && (
        <Section color="info">
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Bestill tid hos oss</h2>
          <div className="grid gap-5 md:grid-cols-2 lg:grid-cols-3">
            {overrideRelatedServicesList.map((service) => {
              return <LinkCard key={service._id} title={service.title} href={service.href} />;
            })}
          </div>
        </Section>
      )}
      {!overrideRelatedServicesList?.length && !!relatedServicesList?.length && (
        <Section color="info">
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Bestill tid hos oss</h2>
          <div className="grid gap-5 md:grid-cols-2 lg:grid-cols-3">
            {relatedServicesList.map((service) => {
              return <LinkCard key={service._id} title={service.title} href={service.href} />;
            })}
          </div>
        </Section>
      )}
      {/* OPENINGHOURS */}
      <Section color="primary">
        <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Våre åpningstider</h2>
        <div className="flex flex-col gap-10">
          {isEaster && <SpecialOpeningHours openingHours={easterOpeningHours} />}
          {isChristmas && <SpecialOpeningHours openingHours={christmasOpeningHours} />}
          {isSummer && <SpecialOpeningHours openingHours={summerOpeningHours} />}

          <div>
            {hasSpecialOpeningHours && (
              <h3 className="text-display-3 col-span-3 mb-5">Ordinære åpningstider</h3>
            )}

            <OpeningHours
              hasSpecialOpeningHours={hasSpecialOpeningHours}
              openingHoursSale={openingHoursSale}
              openingHoursGarage={openingHoursGarage}
              openingHoursWarehouse={openingHoursWarehouse}
            />
          </div>
        </div>
      </Section>
      {/* LINK TO EXTERNAL BRAND SITES */}
      {carBrandsList.length > 0 && (
        <Section color="background">
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">
            Vi er bilforhandler og merkeverksted for
          </h2>
          <div className="grid gap-5 md:grid-cols-2 lg:grid-cols-3">
            {carBrandsList.map((brand) => {
              return (
                <LinkCard
                  key={brand.linkText}
                  title={brand.linkText}
                  href={brand.href}
                  color="beige-light"
                />
              );
            })}
          </div>

          {!!filteredServiceCarBrands?.length && (
            <div className="mt-16">
              <h3 className="mb-5 text-xl font-bold sm:text-2xl">Vi utfører også service på</h3>
              <ul className="mt-5 flex gap-2 text-lg sm:text-xl">
                {filteredServiceCarBrands.map((brand) => {
                  return (
                    <li
                      key={brand._id}
                      className="rounded-md border border-black/5 bg-[#f9f7f4] px-6 py-2 text-xl font-bold"
                    >
                      {brand.title}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </Section>
      )}
      {/* USEDCAR LINK */}
      {!isRepairCentre && defaultInfoDealer && (
        <Section>
          <ArticleCardBig
            flip
            overline={defaultInfoDealer.usedCarOverline}
            title={defaultInfoDealer.usedCarTitle}
            description={defaultInfoDealer.usedCarDescription}
            href={usedCarsLink || defaultInfoDealer.usedCarLink}
            linkText="Våre bruktbiler"
            image={defaultInfoDealer.usedCarImage}
          />
        </Section>
      )}
      {/* CONTACT US */}
      <Section>
        <div className="mx-auto flex w-full max-w-4xl flex-col gap-2 sm:gap-4">
          <h2 className="mb-4 text-3xl font-semibold lg:mb-8 lg:text-4xl">Kontakt oss</h2>
          <div className="flex flex-wrap gap-5 lg:justify-between">
            <Link
              href={`tel:${phone}`}
              className={cn(buttonVariants({ variant: 'primary-dark' }), 'whitespace-nowrap')}
            >
              Ring oss på {phone}
            </Link>
            <Link
              href={`mailto:${email}`}
              className={cn(buttonVariants({ variant: 'primary-dark' }), 'whitespace-nowrap')}
            >
              Send e-post til {email}
            </Link>
            <Button
              variant="primary-dark"
              className="whitespace-nowrap"
              onClick={() => openModal()}
            >
              Bruk kontaktskjema
            </Button>
          </div>
        </div>
      </Section>
      {/* ADDRESS AND DIRECTIONS */}
      {Array.isArray(addressInfoList) && !!addressInfoList.length && (
        <Section>
          <div className="mx-auto flex w-full max-w-4xl flex-col gap-2 sm:gap-4">
            <h2 className="mb-4 text-3xl font-semibold lg:mb-8 lg:text-4xl">
              Adresse og veibeskrivelse
            </h2>
            <p className="opacity-80">
              Trykk på {addressInfoList.length > 1 ? 'kortene' : 'kortet'} for å få veibeskrivelse i
              Google Maps
            </p>
            <div className="flex flex-wrap gap-5 lg:justify-between">
              {addressInfoList.map((info) => {
                if (!info.map) return null;
                const fullAddress = `${info.address}, ${info.postCode} ${info.postLocation}`;

                return (
                  <LinkCard
                    key={info.title}
                    title={info.title}
                    description={fullAddress}
                    href={info.map.locationMapURL}
                    color="beige-dark"
                    rounded
                    fullwidth
                  />
                );
              })}
            </div>
          </div>
        </Section>
      )}
      {Array.isArray(localCustomerBenefits) && !!localCustomerBenefits.length && (
        <Section color="info">
          <div className="mx-auto w-full max-w-4xl">
            <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Hos oss</h2>

            <Accordion
              type="single"
              collapsible
              color="secondary"
              defaultValue={
                localCustomerBenefits.length === 1
                  ? localCustomerBenefits[0].customerBenefitStandard.title
                  : undefined
              }
            >
              <div className="grid gap-3">
                {localCustomerBenefits.map((localBenefit) => {
                  const { customerBenefitStandard, info, article } = localBenefit;

                  return (
                    <AccordionItem
                      value={customerBenefitStandard.title}
                      key={customerBenefitStandard.title}
                    >
                      <AccordionTrigger>{customerBenefitStandard.title}</AccordionTrigger>
                      <AccordionContent>
                        <div className="grid gap-5 md:grid-cols-2">
                          <div>
                            <PortableText value={customerBenefitStandard.bodyText} />

                            {info && <p className="mb-4 text-base sm:text-lg">{info}</p>}
                            {customerBenefitStandard.article && (
                              <div className="flex flex-col gap-2">
                                <span className="text-base sm:text-lg">
                                  Vil du vite mer? Les her:
                                </span>
                                <Link
                                  href={customerBenefitStandard.article.href}
                                  className="decoration max-w-max text-base font-medium underline underline-offset-2 hover:decoration-transparent sm:text-lg"
                                >
                                  {customerBenefitStandard.article.listViewTitle ||
                                    customerBenefitStandard.article.title}
                                </Link>
                                {article && (
                                  <Link
                                    href={article.href}
                                    className="decoration max-w-max text-base font-medium underline underline-offset-2 hover:decoration-transparent sm:text-lg"
                                  >
                                    {article.listViewTitle || article.title}
                                  </Link>
                                )}
                              </div>
                            )}
                          </div>
                          {customerBenefitStandard?.image &&
                            customerBenefitStandard?.image?.asset && (
                              <Image
                                width={900}
                                height={900}
                                src={customerBenefitStandard.image}
                                className="w-full rounded-md"
                                priority
                              />
                            )}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  );
                })}
              </div>
            </Accordion>
          </div>
        </Section>
      )}
      {/* PARTNERS */}
      {!!partners?.length && (
        <Section color="tertiary">
          <h2 className="mb-8 text-center text-3xl font-semibold lg:mb-12 lg:text-4xl">
            Vi samarbeider med
          </h2>
          <div className="flex flex-wrap items-center justify-center gap-5">
            {partners.map((partner) => {
              if (partner.href)
                return (
                  <Link
                    href={partner.href}
                    key={partner.title}
                    className="group overflow-hidden rounded-md bg-white p-4 duration-300 hover:bg-white/80"
                  >
                    <div className="relative aspect-video h-16 sm:h-20 md:h-28">
                      <Image
                        src={partner.image}
                        fill
                        className="object-contain duration-300 group-hover:scale-105"
                      />
                    </div>
                  </Link>
                );

              return (
                <div key={partner.title} className="rounded-md bg-white p-4">
                  <div className="relative aspect-video h-16 sm:h-20 md:h-28">
                    <Image src={partner.image} fill className="object-contain" />
                  </div>
                </div>
              );
            })}
          </div>
        </Section>
      )}
      {/* ARTICLES DEALER */}
      {Array.isArray(relatedArticlesFeatureDealer) && !!relatedArticlesFeatureDealer.length && (
        <Section>
          <div className="mx-auto max-w-4xl">
            <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">
              Aktuelt fra {title}
            </h2>
            <div className="grid gap-5">
              {relatedArticlesFeatureDealer.map((article) => {
                return (
                  <>
                    <div className="max-sm:hidden">
                      <ArticleCardHorizontal article={article} />
                    </div>
                    <div className="sm:hidden">
                      <ArticleCard article={article} />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Section>
      )}
    </Layout>
  );
}

export async function getStaticPaths() {
  const paths = await sanityClient.fetch(
    groq`*[_type == "dealer" && defined(slug.current) ][].slug.current`,
  );
  return {
    paths: paths.map((slug: string) => ({ params: { slug } })),
    fallback: 'blocking',
  };
}

export const getStaticProps = async ({ params }: { params: { slug: string } }) => {
  const { slug = '' } = params;
  const data = await sanityClient.fetch(dealerPageQuery, { slug });
  if (!data.content) return { notFound: true };

  return {
    props: {
      data,
      slug,
    },
    revalidate: 1,
  };
};

export default DealerPage;
