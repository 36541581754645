import moment from 'moment-timezone';

function isWithinTimeInterval(timeInterval: string): boolean {
  // Get the current time in Norwegian timezone
  const currentTime = moment().tz('Europe/Oslo');

  // Split the time interval string into start and end times
  const [startTime, endTime] = timeInterval.split(' - ');

  // Parse the start and end times and set the date to today
  const startTimeParsed = moment(startTime, 'HH:mm').tz('Europe/Oslo').set({
    year: currentTime.year(),
    month: currentTime.month(),
    date: currentTime.date(),
  });

  const endTimeParsed = moment(endTime, 'HH:mm').tz('Europe/Oslo').set({
    year: currentTime.year(),
    month: currentTime.month(),
    date: currentTime.date(),
  });

  // Check if the current time is within the interval
  const isWithinRange = currentTime.isBetween(startTimeParsed, endTimeParsed, undefined, '[]');

  return isWithinRange;
}

export default isWithinTimeInterval;
