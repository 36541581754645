import { AnimatePresence, easeInOut, motion } from 'framer-motion';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { OpeningHoursType } from '../types';
import isWithinTimeInterval from '../utils/isWithinTimeInterval';

interface OpeningHoursDropdownProps {
  hasSpecialOpeningHours: boolean;
  hours: {
    [key: string]: string;
  };
  title: string;
}

interface OpeningHoursProps {
  hasSpecialOpeningHours: boolean;
  openingHoursSale?: OpeningHoursType;
  openingHoursGarage?: OpeningHoursType;
  openingHoursWarehouse?: OpeningHoursType;
}

const dropdownVariant = {
  open: {
    height: 'auto',
  },
  closed: {
    height: 60,
  },
};

const dropdownButtonVariant = {
  open: {
    opacity: 1,
    x: 0,
    transition: { duration: 1, ease: 'easeInOut' },
  },
  closed: {
    opacity: 0,
    x: 10,
    transition: { duration: 1, ease: 'easeInOut' },
  },
};

function OpeningHours({
  hasSpecialOpeningHours,
  openingHoursSale,
  openingHoursGarage,
  openingHoursWarehouse,
}: OpeningHoursProps) {
  return (
    <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
      {openingHoursSale && Object.keys(openingHoursSale).length > 1 && (
        <div className="">
          <OpeningHoursDropdown
            hasSpecialOpeningHours={hasSpecialOpeningHours}
            title="Salg"
            hours={openingHoursSale}
          />
        </div>
      )}
      {openingHoursGarage && Object.keys(openingHoursGarage).length > 1 && (
        <div>
          <OpeningHoursDropdown
            hasSpecialOpeningHours={hasSpecialOpeningHours}
            title="Verksted"
            hours={openingHoursGarage}
          />
        </div>
      )}
      {openingHoursWarehouse && Object.keys(openingHoursWarehouse).length > 1 && (
        <div>
          <OpeningHoursDropdown
            hasSpecialOpeningHours={hasSpecialOpeningHours}
            title="Dekk, bilpleie og tilbehør"
            hours={openingHoursWarehouse}
          />
        </div>
      )}
    </div>
  );
}

function OpeningHoursDropdown({ hours, title, hasSpecialOpeningHours }: OpeningHoursDropdownProps) {
  const [expanded, setExpanded] = useState(false);
  const currentDay = moment().format('dddd').toLowerCase();
  const days = Object.keys(hours).filter((day) => day !== '_type'); // All days that is activated in sanity with the toggle.
  const matchedDay = days.find((day) => currentDay === day); // Find if todays day is activated

  const [isWithinRange, setIsWithinRange] = useState(
    matchedDay ? isWithinTimeInterval(hours[matchedDay]) : false,
  );

  useEffect(() => {
    if (matchedDay) {
      const interval = setInterval(() => {
        const result = isWithinTimeInterval(hours[matchedDay]);
        setIsWithinRange(result);
      }, 60000); // 1 minute update interval

      return () => clearInterval(interval);
    }
    return () => {};
  }, [matchedDay, hours]);

  return (
    <div>
      <div className="text-on-background overflow-hidden rounded-sm bg-[#f9f7f4] px-8 py-6">
        <h3 className="mb-2 text-xl font-bold">{title}</h3>
        {!hasSpecialOpeningHours && (
          <div className="border-b border-black/[15%] text-lg font-bold">
            <AnimatePresence mode="wait" initial={false}>
              {isWithinRange ? (
                <div className="flex items-center justify-between py-3">
                  <motion.div
                    key="open"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={dropdownButtonVariant}
                    className="flex items-center gap-2 "
                  >
                    <IoIosCheckmarkCircle className="text-success h-6 min-h-[24px] w-6 min-w-[24px] sm:h-8 sm:w-8" />
                    <span>Åpent nå</span>
                  </motion.div>
                  {matchedDay && <span className="">{hours[matchedDay]}</span>}
                </div>
              ) : (
                <div className="flex items-center justify-between py-3">
                  <motion.div
                    key="closed"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={dropdownButtonVariant}
                    className="flex items-center gap-2 "
                  >
                    <IoIosCloseCircle className="text-error h-6 min-h-[24px] w-6 min-w-[24px] sm:h-8 sm:w-8" />
                    <span>Stengt</span>
                  </motion.div>
                  {matchedDay && <span className="">{hours[matchedDay]}</span>}
                </div>
              )}
            </AnimatePresence>
          </div>
        )}
        <motion.div
          initial={false}
          animate={expanded ? 'open' : 'closed'}
          variants={dropdownVariant}
          transition={{
            duration: 0.3,
            delay: 0.05,
            ease: easeInOut,
          }}
          className="overflow-hidden"
        >
          <button
            type="button"
            onClick={() => setExpanded((prev) => !prev)}
            className="hover:bg-background flex w-full items-center justify-between gap-2 py-4 text-lg font-bold duration-300 active:bg-[#ddd9d3]"
          >
            <span className="text-start">Se åpningstider</span>
            <FiChevronDown className={`${expanded ? 'rotate-180' : ''} h-6 w-6 duration-300`} />
          </button>
          <ul className="inline-flex w-full flex-col divide-y divide-black/[15%] font-bold">
            <li className="grid grid-cols-2 whitespace-nowrap py-4">
              <span>Mandag:</span>
              <span className="text-right">{hours?.monday ? hours.monday : 'Stengt'}</span>
            </li>
            <li className="grid grid-cols-2 whitespace-nowrap py-4">
              <span>Tirsdag:</span>
              <span className="text-right">{hours?.tuesday ? hours.tuesday : 'Stengt'}</span>
            </li>
            <li className="grid grid-cols-2 whitespace-nowrap py-4">
              <span>Onsdag:</span>
              <span className="text-right">{hours?.wednesday ? hours.wednesday : 'Stengt'}</span>
            </li>
            <li className="grid grid-cols-2 whitespace-nowrap py-4">
              <span>Torsdag:</span>
              <span className="text-right">{hours?.thursday ? hours.thursday : 'Stengt'}</span>
            </li>
            <li className="grid grid-cols-2 whitespace-nowrap py-4">
              <span>Fredag:</span>
              <span className="text-right">{hours?.friday ? hours.friday : 'Stengt'}</span>
            </li>
            <li className="grid grid-cols-2 whitespace-nowrap py-4">
              <span>Lørdag:</span>
              <span className="text-right">{hours?.saturday ? hours.saturday : 'Stengt'}</span>
            </li>

            <li className="grid grid-cols-2 whitespace-nowrap py-4">
              <span>Søndag:</span>
              <span className="text-right">{hours?.sunday ? hours.sunday : 'Stengt'}</span>
            </li>
          </ul>
        </motion.div>
        <div />
      </div>
    </div>
  );
}

export default OpeningHours;
