import { RowType, SpecialOpeningHoursType } from '../types';

interface SpecialOpeningHoursProps {
  openingHours: SpecialOpeningHoursType;
}

function SpecialOpeningHours({ openingHours }: SpecialOpeningHoursProps) {
  if (!openingHours) return null;

  return (
    <section>
      <h3 className="text-display-3 mb-5">{openingHours.title}</h3>
      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
        {openingHours?.openingHoursSale && (
          <div className="text-on-background rounded-sm bg-[#f9f7f4] px-8 py-6">
            <p className="mb-2 text-xl font-bold">Salg</p>
            <OpeningHourList rows={openingHours.openingHoursSale.rows} />
          </div>
        )}
        {openingHours?.openingHoursGarage && (
          <div className="text-on-background rounded-sm bg-[#f9f7f4] px-8 py-6">
            <p className="mb-2 text-xl font-bold">Verksted</p>
            <OpeningHourList rows={openingHours.openingHoursGarage.rows} />
          </div>
        )}
        {openingHours?.openingHoursWarehouse && (
          <div className="text-on-background rounded-sm bg-[#f9f7f4] px-8 py-6">
            <p className="mb-2 text-xl font-bold">Deler</p>
            <OpeningHourList rows={openingHours.openingHoursWarehouse.rows} />
          </div>
        )}
      </div>
    </section>
  );
}

export function OpeningHourList({ rows }: { rows: RowType[] }) {
  return (
    <ul className="inline-flex w-full flex-col divide-y divide-black/[15%] font-bold">
      {rows.map(({ cells, _key }) => {
        return (
          <li key={_key} className="grid grid-cols-2 py-4">
            <span>{cells[0]}</span>
            <span className="whitespace-nowrap text-right">{cells[1]}</span>
          </li>
        );
      })}
    </ul>
  );
}

export default SpecialOpeningHours;
